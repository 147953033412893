<footer>
    <div class="container">
      <div class="flex-container justify row">
        <div>
          <p>PDF DOC © 2022</p>
        </div>
        <div>
          <ul>
            <li><a target="_blank" href="https://www.pdfdocapp.com/terms">EULA</a></li>
            <li><a target="_blank" href="https://www.pdfdocapp.com/policy">Privacy</a></li>
            <li><a target="_blank" href="https://www.pdfdocapp.com/pdfdoc-uni">Uninstall</a></li>
            <li><a target="_blank" href="https://www.pdfdocapp.com/pdfdoc-cu">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>