import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'PDF Doc';
  extension = environment.extension;
  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {}
}
