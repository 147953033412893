import { Component, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { UploadService } from  '@services/upload.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
import { IFile } from '@interfaces/IFile'

@Component({
  selector: 'app-convert',
  templateUrl: './convert.component.html',
  styleUrls: ['./convert.component.scss']
})
export class ConvertComponent {
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef
  @ViewChild('progressStatus', {static: false}) progressStatus: ElementRef

  private _progress:string = 'start'
  private progress_list = ['start','loading','cancel','multi','error','alert','finished']
  formats_list = ["doc", "docx", "html", "rtf", "txt", "ppt", "pptx"]
  selectedFormat = this.formats_list[0]
  files:IFile[] = [];
  inProgress:boolean = false;
  isPdf:boolean = false;
  intervalRef = [];
  extension = environment.extension;
  sub: Array<Subscription> = [];
  
  constructor(
    private uploadService: UploadService,
    private router: Router,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  doLoading() {
    if(this.files.length > 1) {
      this.clearScreen('multi')
    } else {
      this.progress = 'loading'
      if( this.files[0].type == 'application/pdf') {
        this.isPdf = true;
      } else {
        this.onSelected('pdf');
      }
    }
  }

  onSelected(value:string): void {
		this.selectedFormat = value;
	}

  reloadScreen() {
    this.clearScreen('start')
  }

  uploadFiles() {
    if(! this.inProgress) {
      this.inProgress = true;
      
      const response = this.uploadService.initConvert(this.files, this.selectedFormat);
      this.files[0].status = 'success'
  
      if(response.error) {
        this.clearScreen(response.error)
      } else {
        const s = response.subscribtion.subscribe(result => {
          if(this.progress == 'loading') {
            if (result['success'] == true) {
              this.inProgress = false;
              this.downloadFile(result)
            } else {
              this.clearScreen('error')
            }
          }
        })
        this.sub.push(s)
      }
    }
  }

  private downloadFile(file) {
    const inIFrame = (window.location != window.parent.location);
    
    if (inIFrame) {
      window.parent.postMessage(
        {
          event_id: 'conversionComplete',
          params: {
            url: file["file"]["url"],
            name: file["file"]["filename"]
          }
        },
        "*"
      );
    }
    else {
      window.location = file["file"]["url"];
    }
    this.progress = 'finished'
  }

  clearScreen(progress:string) {
    if(progress && this.progress_list.includes(progress)) {
      this.progress = progress
    }
    
    this.files = [];
    this.isPdf = false;
    this.fileUpload.nativeElement.value = null;
    this.onSelected(this.formats_list[0]);
    Object.values(this.sub).forEach((value:Subscription, index)=> {
      value.unsubscribe()
    })
    this.sub = []

    while(this.intervalRef.length) {
      this.intervalRef.forEach(element => clearInterval(element));
      this.intervalRef.pop()
    }

    this.inProgress = false;
  }

  onDrop(event) {
    if(event.addedFiles && event.addedFiles.length > 0) {
      this.files.push(...event.addedFiles);
      this.doLoading();
    }
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
    fileUpload.onchange = () => {
      if(fileUpload && fileUpload.files.length > 0) {
        this.files.push(...fileUpload.files);
        this.doLoading();
      }
    }
  }

  set progress(val: string) {
    this._progress = val;
  }

  get progress(): string {
    return this._progress;
  }
}