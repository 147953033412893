<section class="flex-container column">
  <div class="hero-container">
    <div class="container flex-container column align-center">
      <h1 class="main-title">Effortlessly Manage Your PDFs </h1>

      <p class="main-subtitle-2">Our powerful online editor, giving you the tools to create stunning documents</p>

    </div>
  </div>
  <div class="tools-available">


    <div class=" top-blocks">
      <a (click)="GTMService.pushEvent('merge')" href="/merge" [routerLink]="['/merge']" class="main-block">


        <img src="../../../assets/images/merge.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            Merge Files
          </p>
          <p class="text-block-main__subtitle">
            Combines multiple files into one for easier management and collaboration.
          </p>
        </div>
      </a>
      <a (click)="GTMService.pushEvent('convert')" href="/convert" [routerLink]="['/convert']" class="main-block">


        <img src="../../../assets/images/convert-to-word.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            PDF to Word
          </p>
          <p class="text-block-main__subtitle">
            Converts PDFs into editable Word files for easy modification.
          </p>
        </div>
      </a>
      <a (click)="GTMService.pushEvent('convert')" href="/convert" [routerLink]="['/convert']" class="main-block">


        <img src="../../../assets/images/convert-to-pdf.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            Word to PDF
          </p>
          <p class="text-block-main__subtitle">
            Converts Words into editable PDF files for easy modification.
          </p>
        </div>
      </a>

    </div>
    <div class=" top-blocks">
      <a (click)="GTMService.pushEvent('convert')" href="/convert" [routerLink]="['/convert']" class="main-block">


        <img src="../../../assets/images/convert-to-pp.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            PDF to PP
          </p>
          <p class="text-block-main__subtitle">
            Converts PDFs into editable PowerPoint slides.
          </p>
        </div>
      </a>
      <a (click)="GTMService.pushEvent('compress')" href="/compress" [routerLink]="['/compress']" class="main-block">


        <img src="../../../assets/images/compress.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            Compress PDF
          </p>
          <p class="text-block-main__subtitle">
            Reduces PDF file size for easier sharing and storage.
          </p>
        </div>
      </a>
    

    </div>
  </div>
  <div>
    <h1 class="color-cards__main-title">An easy-to-use <br> interface awaits you </h1>
    <div class="color-cards">
      <div class="color-card dark">
        <p class="color-card-title">Merge </p>
        <img src="/images/easy-1.png" alt="">
        <p class="color-card-bottom">Merge multiple PDFs <br> into one in seconds </p>
      </div>
      <div class="color-card">
        <p class="color-card-title">Convert</p>
          <img src="/images/easy-2.png" alt="">
        <p class="color-card-bottom">Convert to and <br> from PDF</p>
      </div>
      <div class="color-card light">
        <p class="color-card-title">Compress </p>
          <img src="/images/easy-3.png" alt="">
        <p class="color-card-bottom">Reduce your PDF file size</p>
      </div>
    </div>
  </div>
  <section class="screen-regular">
    <h2 class="title">{{ content['homeAboutTitle'] }}</h2>
    <div class="blocks ">
        <div class="flex-container column align-center block first-block">
            <img src="/images/works-1.png">
            <h2>{{ content['aboutOneTitle'] }}</h2>
            <p>{{ content['aboutOneText'] }}</p>
        </div>
        <div class="flex-container column align-center block second-block">
            <img src="/images/works-2.png">
            <h2>{{ content['aboutTwoTitle'] }}</h2>
            <p>{{ content['aboutTwoText'] }}</p>
        </div>
        <div class="flex-container column align-center block third-block">
            <img src="/images/works-3.png">
            <h2>{{ content['aboutThreeTitle'] }}</h2>
            <p>{{ content['aboutThreeText'] }}</p>
        </div>
    </div>
</section>
</section>